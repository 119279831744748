import React, {Component, Fragment} from "react";
import Header from "./Header";
import VotesByHour from "./VotesByHour";
import TurnoutTable from "./TurnoutTable";
import ResultsTable from "./ResultsTable";
import axios from "axios";
import Footer from "./Footer";


class Home extends Component {

    constructor(props) {
        super(props);

        this.election_id = 'X0nQ2xOU';


        this.state = {
            snapshot: null,
            election: null,
            loading: false,
            comuna: null
        };
    }

    componentDidMount() {

        this.loadElection(this.election_id);
        this.loadSnapshot(this.election_id);

    }

    loadElection = election_id => {
        this.setState({loading: true}, () =>
            axios({
                method: "get",
                url: process.env.REACT_APP_BACKUP_URL +
                    "/" +
                    election_id +
                    "/header.json"
            })
                .then(response => {
                    this.setState({election: response.data, loading: false});
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        axios({
                            method: "get",
                            url: process.env.REACT_APP_API_URL + "/election/" + election_id

                        })
                            .then(response => {
                                this.setState({election: response.data, loading: false});
                            })
                            .catch(error => {
                                this.setState({loading: false});
                            });

                    } else {
                        this.setState({loading: false});
                    }
                })
        );
    };


    loadSnapshot = election_id => {

        this.setState({loading: true}, () =>
            axios({
                method: "get",
                url:
                    process.env.REACT_APP_BACKUP_URL +
                    "/" +
                    election_id +
                    "/snapshot.json"
            })
                .then(response => {
                    this.setState({
                        loading: false,
                        snapshot: response.data
                    });
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        axios({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/election/" +
                                election_id +
                                "/snapshot"
                        })
                            .then(response => {
                                this.setState({
                                    loading: false,
                                    snapshot: response.data
                                });
                            })
                            .catch(error => {
                                this.setState({loading: false});
                            });
                    } else {
                        this.setState({loading: false});
                    }
                })
        );
    };

    render() {

        return (

            <div className="container-fluid">


                {this.state.election != null && this.state.snapshot != null && (
                    <Fragment>



                            <Header election={this.state.election} logo="https://evoting.s3.amazonaws.com/Concepcion/logo_concepcion.png"
                                    date={this.state.election.configuration.start_date.$date}/>

                        <div className="row justify-content-center m-4 p-4">
                            <div className="col-12 col-sm-11 col-md-9 col-lg-7 text-center">

                                    <h4>Total Votos : {this.state.snapshot.voters.electronic_voted.toLocaleString(navigator.language, {
                                            minimumFractionDigits: 0
                                        })}</h4>

                            </div>
                        </div>

                            <ResultsTable districts={this.state.snapshot.districts}
                                          votantes={this.state.snapshot}
                                          election_id={this.election_id}

                            />

                        <br/><br/>

                    </Fragment>
                )}

                <VotesByHour election_id={this.election_id}/>
                <br/><br/>



                <br/><br/>
                <Footer/>
            </div>
        );
    }
}


export default Home;