import React, {Component, Fragment} from "react";
import "./ResultsTable.css";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

class Questions extends Component {

    render() {
        const questions = (this.props.district ? this.props.questions.filter(q =>
            q.districts.reduce((acc, element) => {
                return (
                    acc || (element.district_id === this.props.district.id)
                );
            }, false)
        ) : this.props.questions).sort(function(a,b) { return(b.districts.length - a.districts.length); });


        return (
            <Fragment>
                {questions.map(q => (
                    <QuestionRow
                        key={q.question_id}
                        question={q}
                        factor={this.props.factor}
                        votantes={this.props.votantes}
                        district={this.props.district}
                    />
                ))}
            </Fragment>
        );
    }
}

class QuestionRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            orden: {
                criterio: "absolute_position",
                ascendente: 1
            }
        };
    }

    doCollapse = event => {
        this.setState({collapsed: !this.state.collapsed});
    };

    setOrden = (criterio, ascendente) => {
        this.setState({

            orden: {
                criterio: criterio,
                ascendente: ascendente
            }

        });
    }


    render() {
        const disabled = this.props.votantes.weighted.ready_disabled + this.props.votantes.weighted.ready_defaulting
            + this.props.votantes.weighted.registered_defaulting + this.props.votantes.weighted.registered_disabled;
        const q = this.props.question;
        const suma = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].total : q.total;
        const dist = this.props.district ? (this.props.votantes.districts[this.props.district.id].weighted.electronic_voted +
            this.props.votantes.districts[this.props.district.id].weighted.ready +
            this.props.votantes.districts[this.props.district.id].weighted.manual_voted +
            this.props.votantes.districts[this.props.district.id].weighted.registered)
            :
            (this.props.votantes.weighted.electronic_voted + this.props.votantes.weighted.ready +
                this.props.votantes.weighted.manual_voted + this.props.votantes.weighted.registered);
        const totals = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].choices : q.totals;
        const blanks = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].blanks : q.blanks;
        const nulls = this.props.district ? q.districts.filter(d => d.district_id === this.props.district.id)[0].nulls : q.nulls;
        const name = this.props.district ? this.props.district.name: q.districts.map(f => f.name).sort().join(', ');

        let orden = (a, b) => {
            return this.state.orden.ascendente * (a.relative_position - b.relative_position);
        }

        switch (this.state.orden.criterio) {
            case "totales":
                orden = (a, b) => {
                    return this.state.orden.ascendente * (q.totals[a.absolute_position] - q.totals[b.absolute_position]);
                }
                break;
            case "opcion":
                orden = (a, b) => {
                    return this.state.orden.ascendente * (b.name.localeCompare(a.name));
                }
                break;
            case "papeleta":
                orden = (a, b) => {
                    return this.state.orden.ascendente * (a.relative_position - b.relative_position);
                }
                break;
            default:
                break;
        }
        return (
            <Fragment>
                <div className="row mt-4">
                    <div className="table-responsive">
                        <table
                            className="table table-bordered table-condensed table-hover table-striped rounded">
                            <tbody>

                            <tr>
                                <th colSpan="4" className="EvColorLightBlue">
                                    {q.name}
                                    <br/>
                                    <small>({name})</small>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="row">

                                        <div className="col-12 ">
                                            Opción
                                        </div>

                                    </div>

                                </th>
                                <th>
                                    <div className="row">

                                        <div className="col-12 text-right">

                                            {q.max_options > 1 ? "Preferencias" : "Votos"}
                                        </div>

                                    </div>


                                </th>


                            </tr>
                            {q.choices.sort(orden).map(c => (
                                <tr className="" key={"Q-" + q.question_id + "-" + c.absolute_position}>

                                    <th >{c.name}</th>
                                    <th className="text-right"
                                        key={"total-" + q.question_id + "-" + c.absolute_position}>
                                        {(
                                            totals[c.absolute_position] / this.props.factor
                                        ).toLocaleString( navigator.language, {
                                            maximumFractionDigits: 2
                                        })}
                                    </th>

                                </tr>
                            ))}
                            <tr  key={"Q-" + q.question_id + "-blancos"}>
                                <th>Blancos</th>
                                <th className="text-right">{(blanks / this.props.factor).toLocaleString(navigator.language, {
                                    maximumFractionDigits: 2
                                })}</th>

                            </tr>

                            <tr key={"Q-" + q.question_id + "-nulos"}>
                                <th>Nulos <smal>{q.max_options > 1 ? "(cada nulo equivale a "+q.max_options + " preferencias)" : ""}</smal></th>
                                <th className="text-right">{(nulls / this.props.factor).toLocaleString(navigator.language, {
                                    maximumFractionDigits: 2
                                })}</th>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <th className="text-right">{(suma + blanks + (nulls * q.max_options)).toLocaleString(navigator.language, {
                                    maximumFractionDigits: 2
                                })}</th>

                            </tr>

                            {this.state.collapsed === false && (
                                <Districts question={q} factor={this.props.factor}/>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }
}

class Districts extends Component {
    render() {
        var q = this.props.question;
        return (
            <Fragment>
                {q.districts.map(d => (
                    <DistrictRow
                        district={d}
                        question={q}
                        key={d.district_id}
                        factor={this.props.factor}
                    />
                ))}
            </Fragment>
        );
    }
}

class DistrictRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
    }

    doCollapse = event => {
        this.setState({collapsed: !this.state.collapsed});
    };

    render() {
        var d = this.props.district;
        var q = this.props.question;
        return (
            <Fragment>
                <tr className="district-row" onClick={this.doCollapse}>
                    <td>{d.name}</td>
                    {q.choices.map(c => (
                        <td
                            key={
                                "D-" +
                                d.district_id +
                                "-" +
                                q.question_id +
                                "-" +
                                c.absolute_position
                            }
                        >
                            {(
                                d.choices[c.absolute_position] / this.props.factor
                            ).toLocaleString(navigator.language, {
                                maximumFractionDigits: 2
                            })}
                        </td>
                    ))}
                    <td>
                        {(d.blanks / this.props.factor).toLocaleString(navigator.language, {
                            maximumFractionDigits: 2
                        })}
                    </td>
                    <td>
                        {(d.nulls / this.props.factor).toLocaleString(navigator.language, {
                            maximumFractionDigits: 2
                        })}
                    </td>
                </tr>

                {this.state.collapsed === false && (
                    <Ballotboxes
                        district={d}
                        question={q}
                        factor={this.props.factor}
                        key={"D-" + d.district_id + "-" + q.question_id}
                    />
                )}
            </Fragment>
        );
    }
}

class Ballotboxes extends Component {
    render() {
        var d = this.props.district;
        var q = this.props.question;
        return (
            <Fragment>
                {d.ballotboxes.map(bx => (
                    <BallotboxRow
                        district={d}
                        question={q}
                        factor={this.props.factor}
                        key={
                            "BX-" +
                            d.district_id +
                            "-" +
                            q.question_id +
                            "-" +
                            bx.ballotbox_id
                        }
                        ballotbox={bx}
                    />
                ))}
            </Fragment>
        );
    }
}

class BallotboxRow extends Component {
    render() {
        var d = this.props.district;
        var q = this.props.question;
        var bx = this.props.ballotbox;
        return (
            <tr
                key={"D-" + d.district_id + "-" + bx.ballotbox_id + "-" + q.question_id}
                className="ballotbox-row"
            >
                <td>{bx.name}</td>
                {q.choices.map(c => (
                    <td
                        key={
                            "D-" +
                            d.district_id +
                            "-" +
                            bx.ballotbox_id +
                            "-" +
                            q.question_id +
                            "-" +
                            c.absolute_position
                        }
                    >
                        {(
                            bx.choices[c.absolute_position] / this.props.factor
                        ).toLocaleString(navigator.language, {maximumFractionDigits: 2})}
                    </td>
                ))}
                <td>
                    {(d.blanks / this.props.factor).toLocaleString(navigator.language, {
                        maximumFractionDigits: 2
                    })}
                </td>
                <td>
                    {(d.nulls / this.props.factor).toLocaleString(navigator.language, {
                        maximumFractionDigits: 2
                    })}
                </td>
            </tr>
        );
    }
}

class ResultsTable extends Component {
    constructor(props) {
        super(props);

        let d = null;

        this.state = {
            results: null,
            factor: null,
            loading: false,
            district: null
        };
    }

    setData = results => {
        const questions = Object.keys(results.questions).map(q => {
            const question = results.questions[q];
            question.choices = question.choices.sort((a, b) => {
                if (a.relative_position < b.relative_position) return -1;
                if (a.relative_position === b.relative_position) {
                    if (a.absolute_position < b.absolute_position) return -1;
                    if (a.absolute_position === b.absolute_position) return 0;
                    return 1;
                }
                return 1;
            });
            question.totals = {};
            question.total = 0;
            question.districts = Object.keys(question.districts).map(d_id => {
                var district = question.districts[d_id];
                district.choices = {};
                district.ballotboxes = Object.keys(district.ballotboxes).map(bx_id => {
                    var ballotbox = district.ballotboxes[bx_id];
                    var choices = {};

                    ballotbox.choices.forEach(bt => {
                        district.choices[bt.absolute_position] = district.choices[
                            bt.absolute_position
                            ]
                            ? district.choices[bt.absolute_position] + bt.total
                            : bt.total;

                        question.totals[bt.absolute_position] = question.totals[
                            bt.absolute_position
                            ]
                            ? question.totals[bt.absolute_position] + bt.total
                            : bt.total;

                        question.total += bt.total;
                        choices[bt.absolute_position] = bt.total;
                        district.total = district.total
                            ? district.total + bt.total
                            : bt.total;

                    });

                    district.blanks = district.blanks
                        ? district.blanks + ballotbox.blanks
                        : ballotbox.blanks;
                    district.nulls = district.nulls
                        ? district.nulls + ballotbox.nulls
                        : ballotbox.nulls;
                    question.blanks = question.blanks
                        ? question.blanks + ballotbox.blanks
                        : ballotbox.blanks;
                    question.nulls = question.nulls
                        ? question.nulls + ballotbox.nulls
                        : ballotbox.nulls;

                    ballotbox.choices = choices;
                    return ballotbox;
                });
                district.ballotboxes.forEach(bx => {
                    if (bx.paper) {
                        Object.keys(bx.paper).forEach(dp_id => {
                            Object.keys(bx.paper[dp_id].ballotboxes).forEach(bx_id => {
                                var ballotbox = bx.paper[dp_id].ballotboxes[bx_id];
                                ballotbox.choices.forEach(bt => {
                                    district.choices[bt.absolute_position] = district.choices[
                                        bt.absolute_position
                                        ]
                                        ? district.choices[bt.absolute_position] + bt.total
                                        : bt.total;

                                    question.totals[bt.absolute_position] = question.totals[
                                        bt.absolute_position
                                        ]
                                        ? question.totals[bt.absolute_position] + bt.total
                                        : bt.total;

                                    question.total += bt.total;
                                });
                                district.blanks = district.blanks
                                    ? district.blanks + ballotbox.blanks
                                    : ballotbox.blanks;
                                district.nulls = district.nulls
                                    ? district.nulls + ballotbox.nulls
                                    : ballotbox.nulls;
                                question.blanks = question.blanks
                                    ? question.blanks + ballotbox.blanks
                                    : ballotbox.blanks;
                                question.nulls = question.nulls
                                    ? question.nulls + ballotbox.nulls
                                    : ballotbox.nulls;


                            });
                        });
                    }
                })


                return district;
            });
            return question;
        });
        results.questions = questions;

        this.setState({
            results: results,
            factor: results.factor,
            loading: false
        });

    };

    addManual = () => {
        console.log(this.state.results);
        this.state.results.questions.forEach(q => {
            q.districts.forEach(d => {

            })
        })
    }


    componentDidMount() {
        this.setState({loading: true}, () =>
            axios
                .get(
                    process.env.REACT_APP_BACKUP_URL +
                    "/" +
                    this.props.election_id +
                    "/results.json"
                )
                .then(response => {
                    var results = response.data;

                    this.setData(results);
                    if (this.props.district) {
                        this.setDistrictById(this.props.district);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        axios({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/election/" +
                                this.props.election_id +
                                "/results"
                        })
                            .then(response => {
                                var results = response.data;
                                this.setData(results);
                                if (this.props.district) {
                                    this.setDistrictById(this.props.district);
                                }
                            })
                            .catch(error => {
                                this.setState({loading: false});
                            });
                    } else {
                        this.setState({loading: false});
                    }
                })
        );
    }

    setDistrictById = (value) => {
        if (value === "0") {
            this.setState({
                district: null
            });
        } else {
            let d = this.props.districts[value];
            d.id = value;
            this.setState({
                district: d
            });
        }

    };

    setDistrict = (event) => {
        this.setDistrictById(event.target.options[event.target.selectedIndex].value);
    };

    render() {
        if (this.state.results === null) {
            return <div>{this.state.loading && <LoadingSpinner/>}</div>;
        } else {
            return (
                <Fragment>
                    <div className="row EvColor text-white p-2 justify-content-center">
                        <div className="col-6 col-sm-6 col-md-5 col-lg-4">


                            <h4>Resultados {this.props.name}</h4>

                        </div>
                        {!this.props.district && (
                            <div className="col-6 col-sm-5 col-md-4 col-lg-3 text-right">

                                {Object.keys(this.props.districts).length > 1 && (
                                    <select onChange={(e) => this.setDistrict(e)}>
                                        <option disabled>Distrito</option>
                                        <option value="0">Todas las comunas</option>
                                        {Object.keys(this.props.districts).map(d => (
                                            <option value={d} key={d}>{this.props.districts[d].name}</option>
                                        ))
                                        }
                                    </select>
                                )
                                }


                            </div>

                        )}

                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11 col-md-9 col-lg-7">

                            <Questions
                                questions={this.state.results.questions}
                                factor={this.state.factor}
                                votantes={this.props.votantes}
                                district={this.state.district}
                            />
                        </div>
                    </div>
                </Fragment>

            );
        }
    }
}

export default ResultsTable;