import React, {Component, Fragment} from "react";

var dateFormat = require("dateformat");

dateFormat.i18n = {
  dayNames: [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  monthNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre"
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"]
};

class Header extends Component {
  render() {
    return (
         <Fragment>
           {this.props.logo && (
               <div className="row justify-content-center m-4">
                 <div className="col-12 col-sm-11 col-md-9 col-lg-7 text-center">
                   <img
                       className="img-fluid logo"
                       alt="Logo"
                       src={this.props.logo}
                   />
                 </div>
               </div>
           )}



           <div className="row justify-content-center">
             <div className="col-12 col-sm-11 col-md-9 col-lg-7">
            <div className="row justify-content-center mb-1">

              <div className="col-12 col-sm-6 col-md-4 mb-1 p-0">
                <BoxDate
                  title="Fecha Inicio"
                  date={this.props.date}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mb-1 p-0">
                <BoxDate
                  title="Fecha Fin"
                  date={this.props.election.configuration.end_date.$date}
                />
              </div>
              <div className="col-12 col-md-4 mb-1 p-0">
                <BoxState title="Estado" state={this.props.election.state} />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                <p>
                  Fecha consulta: {dateFormat(new Date(), "longDate")},{" "}
                  {dateFormat(new Date(), "longTime")}
                </p>
              </div>
            </div>
             </div>
           </div>
         </Fragment>

    );
  }
}

class BoxState extends Component {
  translate = state => {
    switch (state) {
      case "open":
        return "Abierta";
      case "closed":
        return "Cerrada";
      case "summed":
        return "Sumada";
      case "decrypting":
        return "Desencriptando";
      case "finished":
        return "Terminada";
      default:
        return state;
    }
  };
  render() {
    return (
        <div className="row p-2 rounded h-100 EvColorGrisLight m-1">
          <div className="col-12">
            <div className="row">
              <strong>
                {this.props.title}
              </strong>
            </div>
            <div className="row">
              <div className="col-12 text-center p-0">
                {this.translate(this.props.state)}
              </div>
            </div>
          </div>


        </div>
    );
  }
}

class BoxDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    };
  }

  render() {
    return (
      <div className="row EvColorGrisLight p-2 m-1 rounded h-100">
        <div className="col-12">
          <div className="row">
            <strong>
              {this.props.title}
            </strong>
          </div>
          <div className="row">
            <div className="col-12 text-center p-0">
                {dateFormat(this.props.date, "dd 'de' mmmm 'de' yyyy")}
                <br />
                {dateFormat(this.props.date, "HH:MM:ss Z")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;