import React, { Component } from "react";
import "./App.css";
import Home from "./Home";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

const queryString = require("query-string");

class App extends Component {
	render() {
		return (
			<Router >
				<div>
					<Route path="/" exact component={Home} />
					<Route path="/:comuna" exact component={Home} />
				</div>
			</Router>
		);
	}
}


export default App;