import React, {Component, Fragment} from "react";

import "./TurnoutTable.css";

class TurnoutTable extends Component {

    render() {

        return (
            <Fragment>
                <div className="row EvColorGris justify-content-center text-white p-3">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                        <h4>Participación actual por Comuna</h4>
                    </div>
                </div>
                <br/>
                <div className="row justify-content-center">

                    <div className="col-12 col-sm-11 col-md-9 col-lg-7  p-0">
                        <table className="table table-bordered table-condensed table-turnout">
                            <thead>
                            <tr>
                                <th>Comuna</th>
                                <th>Votos </th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(this.props.snapshot.districts).map(d => (
                                <DistrictRow
                                    key={d}
                                    district={this.props.snapshot.districts[d]}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }

}

class DistrictRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
    }

    doCollapse = event => {
        this.setState({collapsed: !this.state.collapsed});
    };

    render() {

        return (
            <React.Fragment>
                <tr className="district-row" >
                    <td>{this.props.district.name}</td>

                    <td>
                        <div className="text-center">
                            {(this.props.district.voters.electronic_voted + this.props.district.voters.manual_voted).toLocaleString(
                                navigator.language,
                                {maximumFractionDigits: 0}
                            )}

                        </div>


                    </td>
                </tr>

            </React.Fragment>
        );
    }
}

class BallotBoxRow extends Component {
    render() {
        const barStyle = {
            width: 100*this.props.ballotbox.voters.electronic_voted/(this.props.ballotbox.voters.electronic_voted +
                this.props.ballotbox.voters.ready) + '%',
            'backgroundColor': '#e7501e47'
        };
        return (
            <tr className="ballotbox-row">
                <td>{this.props.ballotbox.name}</td>
                <td>
                    <div className="text-center">
                        {(this.props.ballotbox.voters.electronic_voted + this.props.ballotbox.voters.manual_voted).toLocaleString(
                            navigator.language,
                            {maximumFractionDigits: 0}
                        )}{" "}
                        de{" "}
                        {(
                            this.props.ballotbox.voters.electronic_voted +
                            this.props.ballotbox.voters.manual_voted +
                            this.props.ballotbox.voters.ready
                        ).toLocaleString(navigator.language, {
                            maximumFractionDigits: 0
                        })}
                    </div>
                    <div className="progress">

                    <div className="progress-bar" role="progressbar"
                         style={barStyle}
                         aria-valuenow={this.props.ballotbox.voters.electronic_voted} aria-valuemin="0"
                         aria-valuemax={
                             this.props.ballotbox.voters.electronic_voted +
                             this.props.ballotbox.voters.ready
                         }></div>
                    </div>

                </td>
            </tr>
        );
    }
}

export default TurnoutTable;