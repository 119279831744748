import React, {Component, Fragment} from "react";

const version = require('../package.json');

class Footer extends Component {


    render() {
        return (
            <Fragment>
                <div className="row EvColor text-white p-1 justify-content-center">
                    <div className="col-12 mb-2  text-center">
                        Consultas y soporte técnico: +562 2840 1459 consulta@evoting.cl
                        <br/>
                        <small>Votación realizada con tecnología de E-Voting</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right">
                        <small>© E-Voting 2019, v. {version.version}</small>
                    </div>
                </div>
            </Fragment>
        );
    }
}


export default Footer;